import React from 'react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { Chart, ArcElement } from 'chart.js';
import {
  ChartContainer,
  TableLine,
  TitleContainer,
  WrongValueText,
} from './PortfolioValueChart.styles';
import DonutChartSkeleton from '@components/molecules/portfolio/Chart/DonutChart/DonutChart.loading';
import LegendChartSkeleton from '@components/molecules/portfolio/Chart/DonutChart/LegendChart/LegendChart.loading';
import TitleIconResponsiveSkeleton from '@components/molecules/navigation/TitleIconResponsive/TitleIconResponsive.loading';

Chart.register(ArcElement);
``;

const PortfolioValueChartSkeleton: React.FC<{
  displayFeedbackLinkSkeleton?: boolean;
}> = ({ displayFeedbackLinkSkeleton }) => {
  return (
    <Stack direction="column" spacing={6} sx={{ flex: 1 }}>
      <TitleContainer>
        <TitleIconResponsiveSkeleton marginIcon src="/values.svg" alt="values">
          <Skeleton width={70} />
        </TitleIconResponsiveSkeleton>
        <Typography variant="paragraphMedium">
          <Skeleton />
        </Typography>
      </TitleContainer>
      <Box>
        <ChartContainer>
          <TableLine>
            <DonutChartSkeleton />
          </TableLine>
          <LegendChartSkeleton />
        </ChartContainer>
        {displayFeedbackLinkSkeleton && (
          <Skeleton>
            <WrongValueText variant="paragraphSmall">
              Do you think this value is wrong? tell us why Here
            </WrongValueText>
          </Skeleton>
        )}
      </Box>
    </Stack>
  );
};

export default PortfolioValueChartSkeleton;
