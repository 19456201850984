import React, { useEffect, useMemo, useState } from 'react';
import { EventCard } from '@components/molecules/events/EventCard';
import { LiveMap } from '@components/organisms/map/LiveMap';
import {
  HorizontalPaginatonList,
  LiveScreenContainer,
  MapContainer,
  MobileMargin,
} from '@components/templates';
import {
  useAllEvents,
  useDisplayedFootprints,
} from '@modules/services/services-events';
import {
  Event,
  EventDetail as EventDetailType,
  EventLayerProperties,
} from '@modules/types/events.types';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LiveEventSkeleton from './LiveEvents.loading';
import { EventDetail } from '@components/organisms/event/EventDetail';
import { useLocationMap } from '@components/organisms/map/GlobalMap/useSiteMap';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import {
  usePortfolioValue,
  useSiteList,
} from '@modules/services/services-sites';
import { FirstPage } from '@mui/icons-material';
import {
  ButtonDetail,
  EventDetailTopContainer,
} from '@components/organisms/event/EventDetail/EventDetail.styles';
import {
  useEventMap,
  useEventState,
} from '@components/organisms/map/GlobalMap/useEventMap';
import { UseQueryResult } from '@tanstack/react-query';
import { ButtonClose } from '@components/atoms/ButtonClose';
import { EventStatus } from '@components/molecules/events/EventStatus';
import { useCompany } from '@modules/services/services-company';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  const [selectedAreaOfInterest, setSelectedAreaOfInterest] =
    useState<GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>>();

  const { data: events } = useAllEvents();
  const currentEvents = useMemo(
    () => events?.items?.filter((event) => event.status === 'active'),
    [events]
  );
  const { data: locations } = useSiteList();
  const { data: company } = useCompany();

  const { selectedEvent, setSelectedEvent } = useEventState(events);

  const locationsMap = useLocationMap<EventLayerProperties>(
    locations?.items || [],
    {
      interactiveLayers: {
        ids: ['aoi'],
        onLayerClick: ({ id }) => setSelectedEvent(id),
      },
    }
  );

  const { onZoomOnFootprint, onMove, eventsToDisplayFootprint } = useEventMap(
    locationsMap.mapRef,
    events,
    selectedEvent
  );

  const eventsResult: UseQueryResult<EventDetailType>[] =
    useDisplayedFootprints(eventsToDisplayFootprint);

  const displayedEvents: EventDetailType[] = useMemo(
    () =>
      eventsResult.reduce(
        (
          dataList: EventDetailType[],
          result: UseQueryResult<EventDetailType>
        ) => (result.data ? dataList.concat(result.data) : dataList),
        []
      ) as EventDetailType[],
    [eventsResult]
  );
  useEffect(() => {
    if (selectedAreaOfInterest?.id !== selectedEvent?.id) {
      setSelectedAreaOfInterest(
        displayedEvents.find((event) => event.id === selectedEvent?.id)
          ?.areaOfInterest
      );
    }
  }, [selectedAreaOfInterest, displayedEvents]);

  useEffect(() => {
    const footprintArea = displayedEvents.find(
      (event) => event.id === selectedEvent?.id
    )?.areaOfInterest;
    setSelectedAreaOfInterest(footprintArea);
    if (selectedEvent) {
      return onZoomOnFootprint(8)(selectedEvent, footprintArea);
    }
  }, [selectedEvent]);

  const { data: portfolioValue } = usePortfolioValue();
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );
  if (!(events && locations && portfolioValue && company)) {
    return <LiveEventSkeleton />;
  }

  return (
    <LiveScreenContainer>
      {isSmall && selectedEvent ? (
        <MobileMargin>
          <EventDetail
            event={selectedEvent}
            onZoomIn={() =>
              onZoomOnFootprint(8)(selectedEvent, selectedAreaOfInterest)
            }
          >
            <EventDetailTopContainer>
              <ButtonClose
                title={t('action.close')}
                onClose={() => setSelectedEvent(undefined)}
              />
              <Box>
                <EventStatus status={selectedEvent.status} />
              </Box>
            </EventDetailTopContainer>
          </EventDetail>
        </MobileMargin>
      ) : (
        <HorizontalPaginatonList
          keyExtractor={(event) => event.id}
          items={events?.items}
          renderItem={(event: Event) => (
            <EventCard
              key={event.id}
              id="event-card"
              event={event}
              onClick={setSelectedEvent}
              isSelected={selectedEvent && selectedEvent?.id === event.id}
            />
          )}
        />
      )}
      <MapContainer>
        <LiveMap
          locationsMap={locationsMap}
          isSmall={isSmall}
          totalValue={portfolioValue.total}
          data={currentEvents}
          displayedEvents={displayedEvents}
          selected={selectedEvent}
          onMove={onMove}
          hoveredFeature={locationsMap.hoveredFeature}
          onClick={setSelectedEvent}
          noLiveEventsText={t('liveevents.no-ongoing-events')}
          numberOfSites={locations.items.length}
          companyCurrency={company?.currencyIso3}
        >
          {!isSmall && selectedEvent && (
            <EventDetail
              event={selectedEvent}
              onZoomIn={() =>
                onZoomOnFootprint(8)(selectedEvent, selectedAreaOfInterest)
              }
            >
              <ButtonDetail
                id="event-detail-close"
                variant="contained-unchecked"
                startIcon={<FirstPage />}
                onClick={() => setSelectedEvent(undefined)}
              >
                {t('liveevents.detail.close')}
              </ButtonDetail>
            </EventDetail>
          )}
        </LiveMap>
      </MapContainer>
    </LiveScreenContainer>
  );
};

export default withErrorBoundary(Screen);
